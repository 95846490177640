import request from '@/plugins/request'

// 提款管理

//提款管理-列表
export function extractList(data = {}){
  return request({
    url: `/api/money/balanceExtract/getExtractList`,
    method: 'POST',
    data: data,
  })
}

//获取提款设置
export function getExtractSet(){
  return request({
    url: `/api/money/extractSet/getExtractSet`,
    method: 'POST',
    data: {},
  })
}
//编辑提现设置
export function updateExtractSet(data = {}){
  return request({
    url: `/api/money/extractSet/updateExtractSet`,
    method: 'POST',
    data: data,
  })
}
//审核提现申请
export function approve(data = {}){
  return request({
    url: `/api/money/balanceExtract/approvalExtract`,
    method: 'POST',
    data: data,
  })
}
//打款
export function payment(params = {}){
  return request({
    url: `/api/money/balanceExtract/payment`,
    method: 'GET',
    params: params,
  })
}


// 开票管理
export function invoiceList(data = {}){
  return request({
    url: `/api/user/invoice/invoiceList`,
    method: 'POST',
    data: data,
  })
}




// 订单结算

// 订单结算列表
export function closeoutList(data = {}){
  if(data.isSettlement) data.isSettlement = Number(data.isSettlement)
  
  // const params = {
  //   ...data,
  //   isSettlement: Number(data.isSettlement),
  //   createTimeEnd: new Date(data.createTimeEnd).getTime(),
  //   createTimeStart: new Date(data.createTimeStart).getTime(),
  //   settlementTimeEnd: new Date(data.settlementTimeEnd).getTime(),
  //   settlementTimeStart: new Date(data.settlementTimeStart).getTime(),
  // }

  return request({
    url: '/api/order/parent/getSettlementList',
    method: 'POST',
    data: data,
  })
}
//获取结算明细
export function getCloseoutById(itemId = ''){
  return request({
    url: '/api/order/detailed/getSettlementDetailed',
    method: 'GET',
    params: {orderId: itemId}
  })
}
//获取结算设置
export function getCloseoutSet(){
  return request({
    url: '/api/money/divdedSet/getDivdedSet',
    method: 'POST',
    data: {},
  })
}
//编辑结算设置
export function editCloseoutSet(data = {}){
  return request({
    url: '/api/money/divdedSet/updateDivdedSet',
    method: 'POST',
    data: data,
  })
}

export default {
  // 提款管理
  extractList,
  getExtractSet,
  updateExtractSet,
  approve,
  payment,
  // 订单查询

  // 开票管理
  invoiceList,

  //订单结算
  closeoutList,
  getCloseoutById,
  getCloseoutSet,
  editCloseoutSet,
}